// 查看课程的弹框
<template>
  <div class="look_lesson_dialog">
    <CommonDialog @close="close" :showDialog="showDialog" :title="title" :width="width">
      <template v-slot:content>
        <div class="dialog_content">
          <!-- 内容视图 -->
          <div class="dv_table_view_content" v-if="showLoading == 1">
            <el-table ref="table_l_l_d" style="width: 98%; margin: auto" border stripe :data="tableData" max-height="370">
              <!-- 序号 -->
              <el-table-column type="index" label="序号" align="center" width="80">
                <template scope="scope">
                  <span>{{ (currPage - 1) * pageSize + scope.$index + 1 }}</span>
                </template>
              </el-table-column>

              <!-- 班级名称 -->
              <el-table-column prop="class_name" :show-overflow-tooltip="true" label="班级名称" width="150"></el-table-column>

              <!-- 班级ID  -->
              <el-table-column prop="class_id" :show-overflow-tooltip="true" label="班级ID" width="120"></el-table-column>

              <!-- 所属教师  -->
              <el-table-column prop="name" label="所属教师" width="120"></el-table-column>

              <!-- 教师手机号  -->
              <el-table-column prop="tel" :show-overflow-tooltip="true" label="教师手机号" width="150"></el-table-column>

              <!-- 学习人数  -->
              <el-table-column min-width="20%" prop="student_count" label="学习人数"></el-table-column>
            </el-table>

            <!-- 顶部的分页器部分 -->
            <div class="bom_page margin_bom_20" v-if="tableData && tableData.length > 0">
              <el-pagination
                background
                @current-change="handleCurrentChange"
                :current-page="currPage"
                layout="total, prev, pager, next, jumper"
                :total="totalSize"
              >
              </el-pagination>
            </div>
          </div>

          <!-- 加载视图 -->
          <Loading v-if="showLoading == -1"></Loading>
        </div>
      </template>
    </CommonDialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      //显示加载布局的标识
      showLoading: -1,

      //表格的最大高度
      maxHeight: 0,

      //总的数据条数
      totalSize: 0,

      //每页显示的条数
      pageSize: 10,

      //当前显示的页码
      currPage: 1,

      //表格数据
      tableData: [],
    };
  },
  created() {
    // console.log("传递的参数是:", this.item);
    this.httpData();
  },
  methods: {
    //关闭的方法
    close() {
      this.$emit("close");
    },

    /**
     * pid
     * self_sub_id
     * school_id
     *
     */
    httpData() {
      let param = this.getHttpParams();
      param.type = 3;
      param.page = this.currPage - 1;
      param.pagecount = this.pageSize;
      param.pid = this.item.pid;
      param.self_sub_id = this.item.self_sub_id;
      param.school_id = this.item.school_id;

      this.$http
        .fetchPost(this.$api.SUBJECT_MANAGER, param)
        .then((res) => {
          //显示内容视图
          this.showLoading = 1;
          let result = res.data;

          //将表格滚动到顶部
          this.$nextTick(() => {
            this.$refs.table_l_l_d.bodyWrapper.scrollTop = 0;
          });

          if (result.state == 200) {
            //获取总条数
            this.totalSize = Number.parseInt(result.data.data_total);

            //先清空一下数据
            this.tableData.splice(0, this.tableData.length);

            if (this.totalSize != 0) {
              //证明有数据
              this.isShowEmptyView = false;

              //获取列表数据
              this.tableData = result.data.data_list;

              //组装一下表格数据,显示省市区的地址信息
              this.tableData.forEach((item, index) => {
                //设置索引
                this.$set(item, "index", index);
              });
            } else {
              //显示空视图
              this.isShowEmptyView = true;
            }
          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
          }
        })
        .catch((err) => {
          this.showLoading = 1;
          console.log("请求数据异常");
        });
    },

    //页面改变时触发
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currPage = val;
      // console.log("当前页码是:", this.currPage);
      //重新刷新数据
      this.httpData();
    },
  },
  computed: {},
  components: {},

  props: ["showDialog", "title", "width", "item"],
};
</script>
<style lang="scss" scoped>
.look_lesson_dialog {
  .dialog_content {
    height: 60vh;
    margin: 10px 0;
    display: flex;
    align-items: center;

    //表格内容部分
    .dv_table_view_content {
      width: 97%;
      margin: 0 auto;
      height: 100%;
    }
  }
}
</style>
