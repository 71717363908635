//查看课程
<template>
  <div class="look_lesson">
    <Layout :showLoading="showLoading">
      <template v-slot:content>
        <div class="look_lesson_content">
          <!-- 课程编码,课程名称部分 -->
          <div class="margin_top_20 margin_left_18">
            <span>课程编码：{{ sub_code }}</span>
            <span class="margin_left_20">课程名称：{{ sub_name }}</span>
          </div>

          <!-- 学校编码,查询日期,搜索,导出 -->
          <div class="action margin_left_18 margin_top_6">
            <!-- 学校 -->
            <span>学校：</span>

            <!-- 学校编码 -->
            <el-input
              clearable
              v-model="schoolNum"
              placeholder="请输入学校名称或编码"
            ></el-input>

            <!-- 日期选择 -->
            <div class="dv_time">
              <!-- 查询日期 -->
              <span class="margin_left_20">查询日期：</span>
              <!-- 日期选择器 -->
              <el-date-picker
                value-format="yyyy-MM-dd"
                v-model="chooseTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                align="right"
              >
              </el-date-picker>
            </div>

            <!-- 搜索 -->
            <!-- <div :class="[isArrowSearch?'search_bt_active':'search_bt_normal']" class="com_bt_action  margin_left_14" @click.stop="search">搜索</div> -->

            <!-- 占位 -->
            <span style="flex: 1"></span>

            <!-- 导出 有数据时才显示导出按钮 class="com_bt_action search_bt_active margin_left_14 margin_right_18" -->
            <el-button
              :loading="showExportLoading"
              style="margin-right: 18px"
              v-if="totalSize != 0"
              @click.stop="doExportAction"
              >导&nbsp;&nbsp;出</el-button
            >
          </div>

          <!-- 加盟校区 -->
          <div class="margin_top_8 margin_left_18">加盟校区列表</div>

          <!-- 表格部分 -->
          <div class="dv_table_view_content">
            <!-- :style="{width:tableWidth+'px',margin:'auto'}" -->
            <el-table
              ref="table_ll"
              style="width: 98%; margin: auto"
              border
              stripe
              :data="tableData"
              :max-height="maxHeight"
            >
              <!-- 序号 -->
              <el-table-column
                type="index"
                label="序号"
                align="center"
                width="70"
              >
                <template scope="scope">
                  <span>{{
                    (currPage - 1) * pageSize + scope.$index + 1
                  }}</span>
                </template>
              </el-table-column>

              <!-- 校区 -->
              <el-table-column
                :show-overflow-tooltip="true"
                prop="school_name"
                label="校区"
                width="150"
              ></el-table-column>

              <!-- 学校编码 -->
              <el-table-column
                prop="school_id"
                label="学校编码"
                width="90"
              ></el-table-column>

              <!-- 有效期  -->
              <el-table-column
                prop="indateTime"
                :show-overflow-tooltip="true"
                label="有效期"
                min-width="30%"
              ></el-table-column>

              <!-- 状态  -->
              <el-table-column
                prop="stateValue"
                label="状态"
                width="95"
              ></el-table-column>

              <!-- 教师账户数  -->
              <el-table-column
                prop="teacherValue"
                align="center"
                :show-overflow-tooltip="true"
                width="140"
              >
                <!-- 使用自定义表表头插槽实现 -->
                <template slot="header">
                  <div class="f_h">
                    <span>教师账户数</span>
                    <span class="margin_top_2 normal_size"
                      >（剩余有效数/总数）</span
                    >
                  </div>
                </template>
              </el-table-column>

              <!-- 学生账户数  -->
              <el-table-column prop="studentValue" width="140">
                <!-- 使用自定义表表头插槽实现 -->
                <template slot="header">
                  <div class="f_h">
                    <span>学生账户数</span>
                    <span class="margin_top_2 normal_size"
                      >（剩余有效数/总数）</span
                    >
                  </div>
                </template>
              </el-table-column>

              <!-- 加盟时间 -->
              <el-table-column
                prop="create_time"
                label="加盟时间"
                width="140"
              ></el-table-column>

              <!-- 累计学习人数 -->
              <el-table-column
                prop="student_count"
                label="累计学习人数"
                width="108"
              ></el-table-column>

              <!-- 操作 -->
              <el-table-column label="操作" min-width="20%">
                <template slot-scope="scope">
                  <el-button
                    @click="doLookAction(scope.row)"
                    type="text"
                    size="small"
                    >查看</el-button
                  >
                </template>
              </el-table-column>
            </el-table>

            <!-- 顶部的分页器部分 -->
            <div
              class="bom_page margin_bom_20"
              v-if="tableData && tableData.length > 0"
            >
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currPage"
                :page-sizes="pageSizeArr"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalSize"
              >
              </el-pagination>
            </div>
          </div>

          <!-- 查看课程的弹框 -->
          <LookLessonDialog
            v-if="showDialog"
            :item="lookItem"
            @close="showDialog = false"
            title="详情"
            :showDialog="showDialog"
            :width="lookLessonDialogWidth"
          ></LookLessonDialog>
        </div>
      </template>
    </Layout>
  </div>
</template>
<script>
// 查看课程的弹框
import LookLessonDialog from "@/components/dialog/LookLessonDialog.vue";
// 导出表格的操作
import { export_json_to_excel } from "../../Excel/Export2Excel";
export default {
  data() {
    return {
      //是否显示加载布局
      showLoading: 1,

      //是否显示查看课程的弹框
      showDialog: false,

      //查看课程的弹框宽度
      lookLessonDialogWidth: "800px",

      //导出按钮是否显示加载状态
      showExportLoading: false,

      //学校编码
      schoolNum: "",

      //搜索按钮是否激活
      isArrowSearch: false,

      //选择的日期
      chooseTime: "",

      //表格的最大高度
      maxHeight: 0,

      //表格部分的最小宽度
      tableWidth: 0,

      //总的数据条数
      totalSize: 30,

      //条数选择的数据
      pageSizeArr: [6, 8, 10],

      //每页显示的条数
      pageSize: 10,

      //当前显示的页码
      currPage: 1,

      //表格数据
      tableData: [],

      //导出的数据
      exportTableData: [],

      //传递的页面参数
      sub_code: "",
      sub_name: "",
      pid: "",
      self_sub_id: "",
      start_date: "",
      end_date: "",
      school_id_name: "",

      //查看的条目
      lookItem: null,
    };
  },
  created() {
    //初始化表格的最大高度
    this.initData();

    //获取传递参数
    this.getQueryParm();

    //请求页面数据
    this.httpData(0);
  },

  methods: {
    //初始化表格数据
    initData() {
      let clientH = document.documentElement.clientHeight;
      this.maxHeight = clientH - 300;
      //获取表格宽度
      this.tableWidth = this.getTabWidth();
    },

    //获取传递参数
    getQueryParm() {
      let item = this.$route.query;
      //课程编码
      this.sub_code = item.sub_code;
      //课程名称
      this.sub_name = item.sub_name;
      this.pid = item.pid;
      this.self_sub_id = item.self_sub_id;
    },

    /**
     * pid
     * self_sub_id
     * start_date
     * end_date
     * school_id_name
     *
     * type 为1时是导出时请求 为0是正常页面数据请求
     */
    httpData(type) {
      let param = this.getHttpParams();
      param.type = 2;
      if (type == 0) {
        param.page = this.currPage - 1;
        param.pagecount = this.pageSize;
      } else if (type == 1) {
        //导出时一下2个参数都传入0表示请求所有数据
        param.page = 0;
        param.pagecount = 0;
      }

      param.pid = this.pid;
      param.self_sub_id = this.self_sub_id;

      //开始时间
      if (!this.isEmpty(this.start_date)) {
        param.start_date = this.start_date;
      } else {
        param.start_date = "";
      }

      //结束时间
      if (!this.isEmpty(this.end_date)) {
        param.end_date = this.end_date;
      } else {
        param.end_date = "";
      }

      //学校编码
      if (!this.isEmpty(this.schoolNum)) {
        param.school_id_name = this.schoolNum;
      } else {
        param.school_id_name = "";
      }

      this.$http
        .fetchPost(this.$api.SUBJECT_MANAGER, param)
        .then((res) => {
          //显示内容视图
          this.showLoading = 1;
          let result = res.data;

          //将表格滚动到顶部
          this.$nextTick(() => {
            this.$refs.table_ll.bodyWrapper.scrollTop = 0;
          });

          if (result.state == 200) {
            //获取总条数
            this.totalSize = Number.parseInt(result.data.data_total);

            if (type == 0) {
              //非导出请求数据
              //先清空一下数据
              this.tableData.splice(0, this.tableData.length);

              if (this.totalSize != 0) {
                //证明有数据
                this.isShowEmptyView = false;

                //获取列表数据
                this.tableData = result.data.data_list;

                //组装一下表格数据,显示省市区的地址信息
                this.tableData.forEach((item, index) => {
                  //设置索引
                  this.$set(item, "index", index);

                  //设置有效期
                  this.$set(
                    item,
                    "indateTime",
                    `${item.start_date}~${item.end_date}`
                  );

                  //教师账户数
                  this.$set(
                    item,
                    "teacherValue",
                    `${item.teacher_balance}/${item.teacher_num}`
                  );

                  //学生账户数
                  if (
                    this.sub_name == "程序蛙少儿编程" ||
                    this.sub_name == "NCT" ||
                    this.sub_name == "GIE"
                  ) {
                    this.$set(
                      item,
                      "studentValue",
                      `${item.student_balance}/${item.student_num}`
                    );
                  } else {
                    this.$set(item, "studentValue", `-`);
                  }

                  //设置状态
                  this.$set(item, "stateValue", this.getStrByState(item.state));
                });
              } else {
                //显示空视图
                this.isShowEmptyView = true;
              }
            } else if (type == 1) {
              //导出的数据
              if (this.totalSize != 0) {
                //获取需要导出的表格数据
                this.exportTableData = result.data.data_list;

                //组装一下表格数据,显示省市区的地址信息
                this.exportTableData.forEach((item, index) => {
                  //设置索引
                  this.$set(item, "index", index + 1);

                  //设置有效期
                  this.$set(
                    item,
                    "indateTime",
                    `${item.start_date}~${item.end_date}`
                  );

                  //教师账户数
                  this.$set(
                    item,
                    "teacherValue",
                    `${item.teacher_balance}/${item.teacher_num}`
                  );

                  //学生账户数
                  if (
                    this.sub_name == "程序蛙少儿编程" ||
                    this.sub_name == "NCT" ||
                    this.sub_name == "GIE"
                  ) {
                    this.$set(
                      item,
                      "studentValue",
                      `${item.student_balance}/${item.student_num}`
                    );
                  } else {
                    this.$set(item, "studentValue", `-`);
                  }

                  //设置状态
                  this.$set(item, "stateValue", this.getStrByState(item.state));
                });

                //进行导出操作
                this.exportExcel();
              }
            }
          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
          }
        })
        .catch((err) => {
          this.showLoading = 1;
          console.log("请求数据异常");
        });
    },

    //搜索--只针对日期选择进行搜索
    search() {
      if (this.isArrowSearch) {
        this.start_date = this.chooseTime[0];
        this.end_date = this.chooseTime[1];
        // console.log("选择时间是:", this.start_date, this.end_date);
        //判断一下选择的时间间隔,最小1天
        if (!this.isRightTimeRange(this.start_date, this.end_date)) {
          this.showWarnMsg("选择的时间间隔必须大于1天");
          return;
        }
        this.currPage = 1;
        //重新刷新数据
        this.httpData(0);
      }
    },

    //判断两个时间间隔是否大于1天
    isRightTimeRange(beginDate, endDate) {
      let begin = new Date(beginDate).getTime();
      let end = new Date(endDate).getTime();
      let day = (end - begin) / (24 * 60 * 60 * 1000);
      // && day <= 365
      if (day > 1) {
        return true;
      } else {
        return false;
      }
    },

    //每页显示的条数变化时触发
    handleSizeChange(val) {
      this.pageSize = val;
      // console.log("当前页显示的条数是:", this.pageSize);
      //重新刷新数据
      this.httpData(0);
    },

    //页面改变时触发
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currPage = val;
      // console.log("当前页码是:", this.currPage);
      //重新刷新数据
      this.httpData(0);
    },

    //表格中点击查看的操作
    doLookAction(item) {
      this.lookItem = item;
      this.lookItem.pid = this.pid;
      this.lookItem.self_sub_id = this.self_sub_id;
      this.showDialog = true;
    },

    //导出表格的点击事件
    doExportAction() {
      this.showExportLoading = true;
      this.httpData(1);
    },

    //导出表格的方法
    exportExcel() {
      require.ensure([], () => {
        //定义表头
        const tHeader = [
          "序号",
          "校区",
          "学校编码",
          "有效期",
          "状态",
          "教师账户数(剩余有效数/总数)",
          "学生账户数(剩余有效数/总数)",
          "加盟时间",
          "累计学习人数",
        ];
        //定义表格数据的可想的属性key值
        const filterVal = [
          "index",
          "school_name",
          "school_id",
          "indateTime",
          "stateValue",
          "teacherValue",
          "studentValue",
          "create_time",
          "student_count",
        ];
        //把要导出的数据tableData存到list
        const list = this.exportTableData;
        const data = this.formatJson(filterVal, list);
        //导出操作最后一个是表名字
        export_json_to_excel(tHeader, data, this.sub_name);
        //延迟2秒让导出按钮恢复原样
        setTimeout(() => {
          this.showExportLoading = false;
        }, 2000);
      });
    },

    //格式化json的方法
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },

    //获取状态的方法
    //空:所有 0:试用  1:正常 -1:失效到期 -2:禁用 -3:待完善
    getStrByState(state) {
      let res = "";
      switch (Number.parseInt(state)) {
        case 0:
          res = "试用";
          break;
        case 1:
          res = "正常";
          break;
        case -1:
          res = "失效";
          break;
        case -2:
          res = "试用";
          break;
        case -3:
          res = "待完善";
          break;
        default:
          break;
      }

      return res;
    },
  },
  computed: {},
  components: {
    LookLessonDialog,
  },
  watch: {
    //监听学校编码输入值的变化
    schoolNum(newValue, oldValue) {
      if (newValue != oldValue) {
        //刷新页面数据
        this.currPage = 1;
        this.httpData(0);
      }
    },

    //选择时间的监听
    chooseTime(newValue, oldValue) {
      if (!this.isEmpty(newValue)) {
        this.isArrowSearch = true;
        this.start_date = this.chooseTime[0];
        this.end_date = this.chooseTime[1];
        // console.log("选择时间是:", this.start_date, this.end_date);
        //判断一下选择的时间间隔,最小1天,最大12个月
        if (!this.isRightTimeRange(this.start_date, this.end_date)) {
          this.showWarnMsg("选择的时间间隔必须大于1天");
          return;
        }
      } else {
        this.isArrowSearch = false;
        this.start_date = "";
        this.end_date = "";
      }
      //还原页码重新请求数据
      this.currPage = 1;
      this.httpData(0);
    },

    // chooseTime(newValue, oldValue) {
    //     if (!this.isEmpty(newValue)) {
    //         this.isArrowSearch = true;
    //     } else {
    //         this.isArrowSearch = false;

    //         this.start_date = "";
    //         this.end_date = "";
    //         this.currPage = 1;
    //         this.httpData(0);
    //     }
    // },
  },
};
</script>
<style lang="scss" scoped>
.look_lesson {
  height: calc(100vh - 77px);
  margin: 0 auto;
  width: 100%;

  .look_lesson_content {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    ::v-deep .el-table .cell {
      text-align: center;
    }

    //搜索部分
    .action {
      height: 54px;
      display: flex;
      align-items: center;
      min-width: 1100px;
      .el-button {
        padding: 0;
        width: 90px;
        height: 32px;
        line-height: 32px;
        color: white;
        border: none;
        font-size: 14px;
        background: #409eff;
      }

      ::v-deep .el-input__inner {
        height: 30px;
        line-height: 30px;
        padding-left: 6px;
      }

      ::v-deep .el-input__icon {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      ::v-deep .el-input {
        width: 210px;
      }

      ::v-deep .el-input__suffix {
        right: 0px;
      }

      .dv_time {
        display: flex;
        align-items: center;
        ::v-deep .el-range-editor.el-input__inner {
          display: flex;
          align-items: center;
          padding: 0 6px;
          width: 270px;
          height: 32px;
          line-height: 32px;
        }
        ::v-deep {
          .el-date-editor .el-range-input {
            width: 100px;
          }
        }
      }
    }
  }
}
</style>
